<template>
  <!--  快速查找页门店找货流程组件-->
  <div class="quickFind" v-if="quickFind">
    <plansTitle :title="'门店找货流程'"></plansTitle>
    <div class="content wow fadeInUp">
      <div class="tops">
        <div
          class="topsItems"
          v-for="(item, index) in quickFindData1"
          :key="index"
        >
          <img :src="require('@/assets/plans/quickFind/' + item.img)" />
          <div class="topNames">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="bottoms">
        <div
          class="bottomsWarp"
          v-for="(item, index) in quickFindData2"
          :key="index"
        >
          <img :src="require('@/assets/plans/quickFind/' + item.img)" />
          <div class="names">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const quickFindData1 = [
  {
    name: "支持按货号/RFID唯一码找货",
    img: "icon1.png",
  },
  {
    name: "支持无网或弱网环境下都能对RFID产品的感应采集",
    img: "icon2.png",
  },
  {
    name: "支持数据本地缓存，有网环境数据提交",
    img: "icon3.png",
  },
];
const quickFindData2 = [
  {
    name: "选择找货方式",
    img: "1.png",
  },
  {
    name: "开始查找",
    img: "2.png",
  },
  {
    name: "查找实物",
    img: "3.png",
  },
  {
    name: "找到实物",
    img: "4.png",
  },
];
</script>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  created() {
    this.quickFind = "快速查找" == this.$route.query.name;
  },
  components: {
    plansTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .quickFind {
    width: 100%;
    background-color: #f7f8fa;
    padding-bottom: 0.8rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tops {
    display: flex;
    margin-bottom: 44px;
    @if ($searchWidth<1100) {
      flex-direction: column;
      align-items: center;
    }
    .topsItems {
      height: 51px;
      background: #ffffff;
      border-radius: 26px;
      border: 1px solid #5ec7b9;
      margin: 0 10px;
      padding: 0 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      @if ($searchWidth<1100) {
        margin-bottom: 10px;
        width: calc(100% - 110px);
        justify-content: stretch;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
    .topNames {
      margin-left: 13px;
      font-size: 16px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 32px;
      letter-spacing: 1px;
    }
  }
  .bottoms {
    display: flex;
    @if ($searchWidth<1100) {
      flex-wrap: wrap;
    }
  }
  .bottomsWarp {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0.42rem;
    @if ($searchWidth<1100) {
      margin: 0 0 20px;
      width: 50%;
    }
    img {
      width: 228px;
      @if ($searchWidth<1100) {
        width: 100%;
      }
    }
    .names {
      font-size: 16px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      padding: 13px 0.35rem;
      background: #ffffff;
      box-shadow: 0px 2px 10px 9px #f0f5f4;
      margin-top: 30px;
      @if ($searchWidth<1100) {
        margin-top: 10px;
        padding: 5px 35px;
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
