<template>
  <!--  智慧试衣设备组件-->
  <div class="fittingEqument" v-if="fitting">
    <plansTitle :title="'智慧试衣设备'"></plansTitle>
    <div class="subTtile">
      {{
        "利于客户了解更多的商品信息，分析出受客户喜欢的样式、推送试衣搭配信息、分析试衣和销售比"
      }}
    </div>
    <div class="imgs wow fadeInUp">
      <img :src="require('@/assets/plans/fittingRoom/equipment.png')" />
    </div>
  </div>
</template>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  components:{
    plansTitle
  },
  created() {
    this.fitting = "智能试衣间" == this.$route.query.name;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .fittingEqument {
    width: 100%;
    background-image: url("~@/assets/plans/fittingRoom/fittingBG.png");
    padding-bottom: 0.5rem;
    @if ($searchWidth<1100) {
      padding:0 10px 50px;
      box-sizing: border-box;
    }
  }
  .subTtile {
    font-size: 20px;
    text-align: center;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #7a7a7a;
    line-height: 38px;
  }
  .imgs{
    width: 10rem;
    margin: 0 auto;
    margin-top: 0.4rem;
    @if ($searchWidth<1100) {
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
