<template>
  <!--  防盗技术页组件-->
  <div class="stealTechnology" v-if="stealTechnology">
    <plansTitle :title="'RFID防盗防损技术特点'"></plansTitle>
    <div class="contentWarp">
      <div class="content wow fadeInUp">
        <div
          class="itemWarp"
          v-for="(item, index) in stealData"
          :key="index"
          :style="{ alignSelf: index == 1 ? 'flex-start' : '' }"
        >
          <div class="names">
            <img
              :src="require('@/assets/plans/stealTechnology/' + item.icon)"
            />
            <div class="itemName">{{ item.name }}</div>
          </div>
          <div class="merits">
            <div class="meritsWarp">
              <div
                class="meritItem"
                v-for="(itemChild, indexs) in item.merit1"
                :key="indexs"
              >
                <img :src="require('@/assets/hook.png')" />
                <div class="meritName">
                  {{ itemChild }}
                </div>
              </div>
            </div>
            <div class="meritsWarp">
              <div
                class="meritItem"
                v-for="(itemChild, indexs) in item.merit2"
                :key="indexs"
              >
                <img :src="require('@/assets/hook.png')" />
                <div class="meritName">
                  {{ itemChild }}
                </div>
              </div>
            </div>
          </div>
          <img
            class="contentImg"
            :src="require('@/assets/plans/stealTechnology/content.png')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const stealData = [
  {
    name: "手持读写器",
    icon: "icon2.png",
    merit1: ["小巧便携", "成本较低"],
    merit2: ["适用于近距离读写", "10s盘全2000 张标签"],
  },
  {
    name: "防伪芯片",
    icon: "icon1.png",
    merit1: ["全球唯一ID", "无法仿造"],
    merit2: ["无法修改", "灵活安装方式"],
  },
  {
    name: "固定阅读器",
    icon: "icon3.png",
    merit1: ["读取精确", "性能稳定"],
    merit2: ["读距离大于10米", "1s读取200张以上标签"],
  },
];
</script>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  created() {
    this.stealTechnology = "防盗防损" == this.$route.query.name;
  },
  components: {
    plansTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .stealTechnology {
    width: 100%;
    background-image: url("~@/assets/plans/fittingRoom/fittingBG.png");
    padding-bottom: 0.8rem;
  }
  .contentWarp {
    width: 100%;
    @if ($searchWidth<1100) {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
  .content {
    position: relative;
    margin: 0 auto;
    width: 1281px;
    height: 460px;
    box-sizing: border-box;
    padding: 0.43rem 2rem 0.66rem;
    background: linear-gradient(179deg, #fafcff 0%, #ffffff 100%);
    box-shadow: 0px 4px 11px 3px rgba(109, 154, 148, 0.22);
    display: flex;
    align-items: flex-end;
    @if ($searchWidth<1100) {
      width: 900px;
      height: 350px;
    }
    .itemWarp {
      width: 33.3%;
      .names {
        height: 42px;
        width: 289px;
        background: #ffffff;
        border-radius: 21px;
        border: 1px solid #5ec7b9;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 32px;
          height: 32px;
        }
        .itemName {
          font-size: 20px;
          font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
          line-height: 26px;
          letter-spacing: 1px;
        }
      }
      .merits {
        margin-top: 16px;
        display: flex;
        .meritItem {
          display: flex;
          align-items: center;
          margin-right: 0.34rem;
          margin-bottom: 7px;
          img {
            width: 15px;
            height: 12px;
          }
          .meritName {
            margin-left: 12px;
            font-size: 16px;
            white-space: nowrap;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #333333;
            line-height: 25px;
          }
        }
      }
    }
    .contentImg {
      position: absolute;
      width: 347px;
      left: 50%;
      margin-left: -190px;
      bottom: 50px;
      @if ($searchWidth<1100) {
        margin-left: -174px;
        top: 100px;
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
