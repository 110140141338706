<template>
  <!--  贴标管理业务流程组件-->
  <div class="flowPath" v-if="flowPath">
    <plansTitle :title="'标签业务框架'"></plansTitle>
    <div class="nav">
      <div
        class="nav-item"
        :class="{ current: current === index }"
        v-for="(item, index) in systemData"
        :key="index"
        @click="current = index"
      >
        {{ item }}
      </div>
      <div
        class="nav-current"
        :style="`transform:translateX(${100 * current}%)`"
      >
        <div></div>
      </div>
    </div>
    <div class="content wow fadeInUp" v-if="current == 0">
      <div class="topsContent">
        <div
          class="topsItem"
          v-for="(item, index) in flowPathData.topData"
          :key="index"
        >
          <div class="topWarp">
            <img :src="require('@/assets/plans/flowPath/' + item.icon)" />
            <div class="topTitles">
              {{ item.title }}
            </div>
          </div>
          <div class="mes">
            {{ item.mes }}
          </div>
        </div>
      </div>
      <div class="bottomContent">
        <div
          class="bottomItem"
          v-for="(item, index) in flowPathData.endData"
          :key="index"
        >
          <img
            :src="require('@/assets/plans/flowPath/' + item.img)"
            :class="{ rightArrow: index % 2 != 0 }"
          />
          <div class="bottomName">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="content contentSec" v-else>
      <img
        class="flowpathSec"
        :src="require('@/assets/plans/flowPath/flowpath2.png')"
      />
    </div>
  </div>
</template>
<script>
const flowPathData = {
  topData: [
    {
      title: "管理更规范",
      icon: "icon1.png",
      mes: "支持RFID系统权限分配和实时同步拉 通标签下单与货物装箱的管理流程。",
    },
    {
      title: "下单更高效",
      icon: "icon2.png",
      mes: "标签采购下单流程的线上化和电子化降低 线下沟通和订单数据手工处理的繁琐度。",
    },
    {
      title: "数据更准确",
      icon: "icon3.png",
      mes: "商品、供应商和标签的数字化管理标签 下单记录及其数据的实时查询。",
    },
    {
      title: "跟踪更便捷",
      icon: "icon4.png",
      mes: "标签生产进度和订单交期的可视化合格 证卡更新模版的在线预览。",
    },
  ],
  endData: [
    {
      img: "1.png",
      name: "登录标签下单系统",
    },
    {
      img: "toRight.png",
      name: "",
    },
    {
      img: "2.png",
      name: "标签下单并确认",
    },
    {
      img: "toRight.png",
      name: "",
    },
    {
      img: "3.png",
      name: "AI自动排版",
    },
    {
      img: "toRight.png",
      name: "",
    },
    {
      img: "4.png",
      name: "标签生产",
    },
    {
      img: "toRight.png",
      name: "",
    },
    {
      img: "5.png",
      name: "送货到厂",
    },
  ],
};
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {
      current: 0,
      systemData: ["标签下单流程", "标签下单业务流程"],
    };
  },
  components: {
    plansTitle,
  },
  created() {
    this.flowPathData = flowPathData;
    this.flowPath = "贴标管理" == this.$route.query.name;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  img {
    width: 100%;
  }
  .flowPath {
    width: 100%;
    background-color: #f7f8fa;
    padding-bottom: 0.5rem;
  }
  .nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.2rem;
    background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    box-shadow: 0 0.02rem 0.11rem 0 rgba(83, 177, 165, 0.23);
    border: 0.02rem solid #ffffff;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;

    @if ($searchWidth<1100) {
      // width: 9.2rem;
      height: 2.4rem;
      border-radius: 1.2rem;
      width: 100%;
      padding: 0.4rem;
    } @else {
      padding: 0.06rem 0.1rem;
      width: 6.6rem;
      height: 0.62rem;
      border-radius: 0.31rem;
    }

    .nav-item {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.21rem;
      z-index: 10;
      transition: all 0.3s;
      text-align: center;
      cursor: pointer;

      @if ($searchWidth<1100) {
        font-size: 0.6rem;
      } @else {
        font-size: 0.24rem;
      }

      &.current {
        color: #fff;
      }
    }

    .nav-current {
      z-index: 9;
      width: 50%;
      border-radius: 0.21rem;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 0.3s;
      box-sizing: border-box;

      @if ($searchWidth<1100) {
        padding: 0.4rem;
      } @else {
        padding: 0.06rem 0.1rem;
      }

      > div {
        width: 100%;
        height: 100%;
        background: #5ec7b9;
        border-radius: 999999px;
      }
    }
  }
  .content {
    width: 12.6rem;
    margin: 0.5rem auto;
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    box-shadow: 0px 0px 9px 2px rgba(83, 177, 165, 0.22);
    border: 5px solid #ffffff;
    @if ($searchWidth<1100) {
      width: 100%;
    }
  }
  .topsContent {
    display: flex;
    @if ($searchWidth<1100) {
      flex-wrap: wrap;
    }
    .topsItem {
      margin-right: 0.2rem;
      @if ($searchWidth<1100) {
        margin-top: 10px;
        border-bottom: 1px solid #e5e6eb;
      }
    }
    :last-child {
      margin-right: 0;
    }
    .topWarp {
      display: flex;
      align-items: center;
      img {
        width: 32px;
      }
      .topTitles {
        margin-left: 0.1rem;
        font-size: 0.2rem;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 27px;
        @if ($searchWidth<1100) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
    .mes {
      margin-top: 0.18rem;
      font-size: 0.16rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;
      line-height: 24px;
      @if ($searchWidth<1100) {
        font-size: 0.8rem;
        line-height: 1.1rem;
        margin: 10px 0;
      }
    }
  }
  .bottomContent {
    margin-top: 0.5rem;
    display: flex;
    @if ($searchWidth<1100) {
      margin-top: 20px;
      width: 100%;
      overflow: auto;
    }
    .bottomItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      img {
        height: 1rem;
        @if ($searchWidth<1100) {
          height: 100px;
          width: 200px;
        }
      }
      .rightArrow {
        height: 20px;
        @if ($searchWidth<1100) {
          height: 20px;
          width: 24px;
        }
        position: relative;
        top: -15px;
      }
      .bottomName {
        margin-top: 0.2rem;
        font-size: 0.2rem;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 27px;
        white-space: nowrap;
        @if ($searchWidth<1100) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
    :last-child {
      margin-right: 0;
    }
  }
  .contentSec {
    padding: 0.4rem 0;
    .flowpathSec {
      width: 10rem;
    }
    @if ($searchWidth<1100) {
      .flowpathSec {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
