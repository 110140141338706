<template>
  <!--  试衣价值组件-->
  <div class="fittingValue" v-if="fittingValue">
    <plansTitle :title="'RFID智能试衣间的价值'"></plansTitle>
    <div class="content wow fadeInLeftBig">
      <div
        class="merits"
        v-for="(item, index) in fittingValueData"
        :key="index"
      >
        <div class="rightArrow" v-if="item.rightArrow">
          <img :src="require('@/assets/plans/values/right.png')" />
        </div>
        <div class="meritWarp" v-else>
          <div class="topNames">
            <img :src="require('@/assets/plans/fittingRoom/' + item.icon)" />
            <div class="names">
              {{ item.name }}
            </div>
            <div class="deltas"></div>
          </div>
          <div class="mesWarp">
            <img :src="require('@/assets/gou.png')" />
            <div class="meritItem">
              {{ item.mes }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const fittingValueData = [
  {
    name: "提升购物体验",
    icon: "icon6.png",
    mes: "RFID智能试衣间快速提供商品信息、价格、颜色等，避免取下衣服查看标签的时间浪费，方便消费者快速找到合适的尺码和款式。",
  },
  {
    rightArrow: true,
  },
  {
    name: "增强商品展示效果",
    icon: "icon7.png",
    mes: "RFID智能试衣间通过智能投影、LED屏幕等方式，直观展示商品，提高商品吸引力和销售量。",
  },
  {
    rightArrow: true,
  },
  {
    name: "降低库存管理成本",
    icon: "icon8.png",
    mes: "RFID智能试衣间实时监控和管理商品库存，帮助商家降低库存管理成本。",
  },
  {
    rightArrow: true,
  },
  {
    name: " 数据分析和营销优化",
    icon: "icon9.png",
    mes: "RFID智能试衣间帮助商家通过收集消费者试衣数据和购物行为信息，实现更加精准的营销策略。",
  },
];
</script>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  created() {
    this.fittingValue = "智能试衣间" == this.$route.query.name;
  },
  components: {
    plansTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .fittingValue {
    width: 100%;
    background: #f7f8fa;
    padding-bottom: 0.8rem;
  }
  .content {
    display: flex;
    justify-content: center;
    @if ($searchWidth<1100) {
      flex-direction: column;
      align-items: center;
    }
  }
  .merits {
    margin: 0 7px;
  }
  .rightArrow {
    width: 45px;
    display: flex;
    align-items: flex-start;
    @if ($searchWidth<1100) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .meritWarp {
    @if ($searchWidth<1100) {
      margin-bottom: 10px;
    }
    .topNames {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #5ec7b9;
      padding: 5px 0;
      width: 248px;
      border-right: none;
      position: relative;
      img {
        width: 32px;
        height: 32px;
      }
      .names {
        margin-left: 5px;
        font-size: 20px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 26px;
        letter-spacing: 1px;
        white-space: nowrap;
      }
      .deltas {
        width: 29px;
        height: 29px;
        border: 1px solid #5ec7b9;
        position: absolute;
        right: -15px;
        border-right: none;
        border-bottom: none;
        transform: rotate(135deg);
      }
    }
    .mesWarp {
      display: flex;
      width: 272px;
      margin-top: 15px;
      img {
        width: 16px;
        height: 16px;
        transform: translateY(4px);
      }
      .meritItem {
        font-size: 16px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #7a7a7a;
        line-height: 25px;
        letter-spacing: 1px;
        margin-left: 5px;
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
