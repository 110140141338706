<template>
<!--  解决方案介绍组件-->
  <div class="introduce">
    <plansTitle :title="'产品介绍'"></plansTitle>
    <div class="content">
      <div class="left wow fadeInLeftBig">
        <div class="topWarp">
          <img :src="require('@/assets/plans/' + introduce.icon)" />
          <div class="topTitle">{{ introduce.name }}</div>
        </div>
        <div class="mes">{{ introduce.mes }}</div>
      </div>
      <div class="right wow fadeInRightBig">
        <img :src="require('@/assets/plans/' + introduce.img)" />
      </div>
    </div>
  </div>
</template>
<script>
const introduceData = [
  {
    name: "贴标管理",
    icon: "1-1.png",
    mes: "标签是整个RFID服装智慧门店应用工作的基础，使用之前需要电子标签进行编码并与服装进行绑定，完成电子标签与服装信息的绑定，可以配套贴标机进行使用。",
    img: "1-2.png",
  },
  {
    name: "衣服盘点",
    icon: "1-1.png",
    mes: "利用RFID读取设备实现库存货品的快速盘点及高效交接，极大提升作业效率及盘点速度对库存数据精确性的掌握。",
    img: "2-2.png",
  },
  {
    name: "智能货架",
    icon: "3-1.png",
    mes: "应用超高频RFID自动识别技术，为每种每类每个货物进行编号，自动扫描识别货物信息，跟踪货物的状态与位置，并与后台数据库对接，可实现对货物的精准定位、智能存取、自动盘点、实时监控等。",
    img: "3-2.png",
  },
  {
    name: "智能试衣间",
    icon: "4-1.png",
    mes: "RFID读取设备配合后台系统，轻松统计出衣物的试用次数以及购买率，数据分析便于商家调整策略与计划。",
    img: "4-2.png",
  },
  {
    name: "快速查找",
    icon: "5-1.png",
    mes: "在RFID服装智慧门店使用RFID手持式设备，通过对指定服装信息的锁定，可以方便快捷的找到指定服装。",
    img: "5-2.png",
  },
  {
    name: "快速收银",
    icon: "6-1.png",
    mes: "通过固定式RFID收银设备，系统可以高效、精准的采集客户所选商品信息，生产销售清单，大大节省结账时间，使顾客有良好的购物体验，提升品牌形象。",
    img: "6-2.png",
  },
  {
    name: "防盗防损",
    icon: "7-1.png",
    mes: "当未付款的商品离开店面时，RFID服装智慧门店的RFID门禁式设备将及时的检测到该行为，并联动报警，避免企业的损失。",
    img: "7-2.png",
  },
];

import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  components:{
    plansTitle
  },
  created() {
    this.introduce = introduceData.find(
      (item) => item.name == this.$route.query.name
    );
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  img {
    width: 100%;
  }
  .introduce {
    width: 100%;
    background-color: #f7f8fa;
  }
  .content {
    padding: 0 3rem 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @if ($searchWidth<1100) {
      padding: 0 10px;
      flex-direction: column;
      margin-top: 0;
    }
  }
  .left {
    margin-top: -20px;
    @if ($searchWidth>1100) {
      transform: translateY(-20px);
    }
    .topWarp {
      display: flex;
      align-items: center;
    }
    img {
      width: 40px;
      height: 40px;
    }
    .topTitle {
      margin-left: 30px;
      font-size: 0.3rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 42px;
      letter-spacing: 1px;
      white-space: nowrap;
      @if ($searchWidth<1100) {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-left: 5px;
      }
    }
    .mes {
      margin-top: 20px;
      font-size: 0.18rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1d403b;
      line-height: 32px;
      letter-spacing: 1px;
      @if ($searchWidth<1100) {
        font-size: 0.8rem;
        line-height: 1.2rem;
        margin-top: 10px;
      }
    }
  }
  .right {
    margin-left: 70px;
    @if ($searchWidth<1100) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
