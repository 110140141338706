<template>
  <!--  快速收银页痛点组件-->
  <div class="CashierPain" v-if="CashierPain">
    <plansTitle :title="'传统收银的痛点'"></plansTitle>
    <div class="content">
      <div class="left wow fadeInLeftBig">
        <div class="pains" v-for="(item, index) in PainData.left" :key="index" :class="{ leftPain: index > 0 && index < 3 }">
          {{ item }}
        </div>
      </div>
      <div class="centre">
        <div
          class="imgWarp"
          v-for="(item, index) in PainData.imgs"
          :key="index"
          :class="{ centres: index > 0 && index < 3 }"
        >
          <img :src="require('@/assets/plans/fastCash/' + item)" />
        </div>
      </div>
      <div class="right wow fadeInRightBig">
        <div class="pains" v-for="(item, index) in PainData.right" :key="index" :class="{ rightPain: index > 0 && index < 3 }">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const PainData = {
  left: [
    "传统服装行业经常位于高档商圈，租金和人工成本较高",
    "对于营业额较低的小型服装店来说，经营压力很大",
    "传统服装店的收银耗费较长的时间，影响顾客购物体验",
    "传统服装店的收银耗费较长的时间，影响顾客购物体验",
  ],
  right: [
    "扫描的方式容易出现漏扫或者重复扫描的情况，影响准确率",
    "传统服装店的营业额数据通常需要手动总结",
    "难以及时、准确地汇总和分析，影响经营决策",
    "随着消费者需求多样化，传统的收银方式难以满足",
  ],
  imgs: ["left.png", "content1.png", "content2.png", "right.png"],
};
</script>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  created() {
    this.CashierPain = "快速收银" == this.$route.query.name;
  },
  components: {
    plansTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .CashierPain {
    width: 100%;
    background: #ffffff;
    padding-bottom: 0.8rem;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    @if ($searchWidth<1100) {
      width: 100%;
      overflow: auto;
      align-items: stretch;
      justify-content: stretch;
    }
  }
  .left,
  .right {
    @if ($searchWidth<1100) {
      transform: translateY(-12px);
    }
    .pains {
      font-size: 16px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 22px;
      margin: 20px 0;
      @if ($searchWidth<1100) {
        white-space: nowrap;
      }
    }
  }
  .left{
    .pains{
      text-align: right;
    }
    .leftPain{
      margin-right: 18px;
    }
  }
  .right{
    .pains{
      text-align: left;
    }
    .rightPain{
      margin-left: 18px;
    }
  }
  .centre {
    display: flex;
    height: 160px;
    .imgWarp {
      height: 100%;
    }
    .centres {
      margin: 0 15px;
    }
    img {
      height: 100%;
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
