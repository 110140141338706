<template>
  <!--  步骤组件-->
  <div class="step" v-if="introduce">
    <plansTitle :title="introduce.title" class="titles"></plansTitle>
    <div class="content">
      <div class="dottedLine"></div>
      <div class="stepWarp wow fadeInUp">
        <div
          class="stepItem"
          v-for="(item, index) in introduce.steps"
          :key="index"
          :style="styleS(index, introduce.steps.length)"
        >
          <img :src="require('@/assets/plans/step/' + item.icon)" />
          <div class="subTitle">
            {{ item.name }}
          </div>
          <div
            class="meritWarp"
            v-for="(meritItem, idnexs) in item.mes"
            :key="idnexs"
          >
            <img :src="require('@/assets/hook.png')" />
            <div class="merit">
              {{ meritItem }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const styleArr1 = [
  {
    transform: "translateX(-50%)",
  },
  {
    transform: "translateX(-25%) translateY(-73px)",
  },
  {
    transform: "translateY(-92px)",
  },
  {
    transform: "translateX(25%) translateY(-73px)",
  },
  {
    transform: "translateX(50%)",
  },
];
const styleArr2 = [
  {
    transform: "translateX(-50%)",
  },
  {
    transform: "translateX(-30%) translateY(-65px)",
  },
  {
    transform: "translateX(-10%) translateY(-92px)",
  },
  {
    transform: "translateX(10%) translateY(-92px)",
  },
  {
    transform: "translateX(30%) translateY(-65px)",
  },
  {
    transform: "translateX(50%)",
  },
];
const styleS = function (index, length) {
  if (length == 5) {
    return styleArr1[index];
  } else {
    return styleArr2[index];
  }
};
</script>
<script>
const introduceData = [
  {
    name: "贴标管理",
    title: "RFID贴标管理的步骤",
    steps: [
      {
        name: "RFID标签的选择",
        icon: "1-1.png",
        mes: [
          "根据不同的需求和使用环境，选择适合的RFID标签类型，包括标签的尺寸、材质、工作频率等等。",
        ],
      },
      {
        name: "RFID标签的制作",
        icon: "1-2.png",
        mes: [
          "根据服装企业的需求和要求，对RFID标签进行制作和编码，包括标签的序号、批次、生产日期等等。",
        ],
      },
      {
        name: "RFID标签的安装",
        icon: "1-3.png",
        mes: [
          "将制作好的RFID标签粘贴在服装或吊牌上，并记录标签的安装位置和状态，以便后续的追踪和管理。",
        ],
      },
      {
        name: "RFID标签的检测",
        icon: "1-4.png",
        mes: [
          "对安装好的RFID标签进行检测和验证，确保标签的质量和可靠性，以免影响后续的追踪和管理。",
        ],
      },
      {
        name: "RFID标签的维护",
        icon: "1-5.png",
        mes: [
          "在标签使用过程中，定期检查标签的状态、更换损坏的标签等等，以保证标签的正常工作和长期使用。",
        ],
      },
    ],
  },
  {
    name: "衣服盘点",
    title: "RFID服装盘点的步骤",
    steps: [
      {
        name: "准备工作",
        icon: "2-1.png",
        mes: ["安装RFID设备。", "与每件服装的条形码或二维码相关联。"],
      },
      {
        name: "启动RFID读写器",
        icon: "2-2.png",
        mes: ["RFID读写器与电源连接。", "启动RFID读写器，并确保它能够读取标签"],
      },
      {
        name: "RFID盘点",
        icon: "2-3.png",
        mes: ["将衣服放置在读写器附近。", "读取标签后，实时更新位置和状态。"],
      },
      {
        name: "检查结果",
        icon: "2-4.png",
        mes: ["在完成盘点后，对比库存数据。", "读取标签后，核实库存情况。"],
      },
      {
        name: "提交报告",
        icon: "2-5.png",
        mes: [
          "生成盘点报告，提交给相关人员审核。",
          "审核后，同时更新库存数据。",
        ],
      },
      {
        name: "更新库存数据",
        icon: "2-6.png",
        mes: ["更新库存管理系统中的数据。", "方便日后进行跟踪和管理。"],
      },
    ],
  },
  {
    name: "智能货架",
    title: "RFID货架使用步骤",
    steps: [
      {
        name: "导入商品信息",
        icon: "3-1.png",
        mes: ["将商品信息和RFID标签进行绑定，导入到智能货架系统中。"],
      },
      {
        name: "安装RFID天线",
        icon: "3-2.png",
        mes: [
          "安装RFID天线，并连接智能货架系统，确保实时监测RFID标签的运动和位置信息。",
        ],
      },
      {
        name: "放置商品",
        icon: "3-3.png",
        mes: ["将商品放置在智能货架上，RFID标签会自动被识别并与库存系统同步。"],
      },
      {
        name: "客户选购",
        icon: "3-4.png",
        mes: [
          "顾客选购商品时，通过智能货架系统自动减少库存数量，并自动记录购买记录。",
        ],
      },
      {
        name: "付款结账",
        icon: "3-5.png",
        mes: [
          "顾客在付款结账时，智能货架系统会读取RFID标签信息来确认商品信息，然后结算购买的商品费用。",
        ],
      },
    ],
  },

  {
    name: "快速查找",
    title: "RFID服装行业找货流程",
    steps: [
      {
        name: "RFID标签贴附",
        icon: "2-1.png",
        mes: ["将RFID标签贴在每个货物上。", "跟踪货物在供应链中的流动情况。"],
      },
      {
        name: "RFID读取器扫描",
        icon: "4-2.png",
        mes: [
          "RFID读取器扫描标签。",
          "将货物的信息读取到系统中，例如货物的数量、位置、状态等。",
        ],
      },
      {
        name: "存储数据",
        icon: "4-3.png",
        mes: ["将扫描到的信息存储到系统中，建立货物的档案。"],
      },
      {
        name: "查询货物信息",
        icon: "4-4.png",
        mes: ["查询货物信息。", "方便查询货物的位置，数量等。"],
      },
      {
        name: "定位货物",
        icon: "4-5.png",
        mes: [
          "根据系统提供的货物位置信息。",
          "仓库中或者运输途中找到目标货物。",
        ],
      },
      {
        name: "更新数据",
        icon: "4-6.png",
        mes: [
          "找到目标货物，使用RFID读取器扫描标签。",
          "更新货物的信息，生成货物的报告。",
        ],
      },
    ],
  },
  {
    name: "防盗防损",
    title: "RFID防盗防损的步骤",
    steps: [
      {
        name: "EAS系统",
        icon: "5-1.png",
        mes: [
          "基于RFID技术的防盗系统，可用于商店、图书馆、博物馆等场所。可以实现自动化盘点和防盗。",
        ],
      },
      {
        name: "RFID标签锁",
        icon: "5-2.png",
        mes: [
          "特殊的RFID标签，用于高价值物品的防盗，如珠宝、手表等。只能在正确的读取器下解锁，可记录非法解锁时间和位置。",
        ],
      },
      {
        name: "RFID标签粘贴",
        icon: "5-3.png",
        mes: [
          "将RFID标签贴在易被盗的物品上，如包、钱包、电脑等。用于检查是否付款和跟踪物品位置。",
        ],
      },
      {
        name: "RFID视频监控",
        icon: "5-4.png",
        mes: [
          "将RFID技术与视频监控系统相结合，可实现实时监控和跟踪。失去联系时发出警报。",
        ],
      },
      {
        name: "RFID门禁系统",
        icon: "5-5.png",
        mes: [
          "利用RFID技术构建门禁系统，可控制人员进出，保护商店或办公室机密信息的安全。",
        ],
      },
    ],
  },
];
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  components:{
    plansTitle
  },
  created() {
    this.introduce = introduceData.find(
      (item) => item.name == this.$route.query.name
    );
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  ::v-deep(.titles){
    .plansTitle{
      padding-top: 0.8rem;
      padding-bottom: 0.65rem;
    }
  }
  .step {
    width: 100%;
    padding-bottom: 0.8rem;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @if ($searchWidth<1100) {
        width: 100%;
        overflow: auto;
        padding-top: 20px;
        padding-left: 110px;
        align-items: stretch;
        box-sizing: border-box;
      }
    }
    .dottedLine {
      width: 1121px;
      height: 92px;
      background: url("~@/assets/plans/step/arc.png");
      background-size: 100% 100%;
    }
    .stepWarp {
      width: 1121px;
      justify-content: space-around;
      position: relative;
      top: -15px;
      display: flex;
      .stepItem {
        width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        > img {
          width: 40px;
          height: 40px;
        }
        .subTitle {
          margin: 10px 0;
          font-size: 20px;
          font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
          line-height: 27px;
        }
      }
      .meritWarp {
        align-self: flex-start;
        display: flex;
        img {
          margin-top: 3px;
          margin-right: 10px;
          width: 22px;
          height: 18px;
        }
      }
      .merit {
        font-size: 16px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #7a7a7a;
        line-height: 25px;
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
