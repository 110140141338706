<template>
  <!--  快速查找&快速收银视频播放组件-->
  <div
    class="videoShow"
    v-if="videoShowData"
    :class="{ background: videoShowData.background }"
  >
    <plansTitle :title="videoShowData.title"></plansTitle>
    <div class="content">
      <div class="left wow fadeInLeftBig">
        <div class="subTitle">
          {{ videoShowData.subTitle }}
        </div>
        <div class="meritWarp">
          <div
            class="meritItem"
            v-for="(item, index) in videoShowData.merit"
            :key="index"
          >
            <img :src="require('@/assets/gou.png')" />
            <div class="merits">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="right wow fadeInRightBig">
        <video
          :src="require('@/assets/plans/videoShow/' + videoShowData.video)"
          controls="controls"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
const videoShowData = [
  {
    name: "快速查找",
    title: "RFID快速查找现场",
    subTitle: "RFID快速找货优势",
    merit: [
      "使用RFID标签和读取器，可以实现对货物的快速定位和跟踪，提高货物的准确性和实时性。",
      "自动化操作识别和记录货物的信息，减少人工操作和误差，提高操作效率和精度。",
      "通过RFID技术快速找到货物，可以缩短找货时间，降低人力和物力成本，提高物流效率和降低运营成本。",
      "RFID技术可以实时跟踪货物的位置和状态，减少货物的损失和盗窃，提高货物安全性和保障客户权益。",
      "RFID技术可以记录和存储货物的流动信息和历史记录，提供数据分析能力，优化供应链和业务流程，提高企业的竞争力和盈利能力。",
    ],
    video: "find.mp4",
  },
  {
    name: "快速收银",
    title: "RFID快速收银视频演示",
    subTitle: "RFID快速收银的优势",
    background: true,
    merit: [
      "自动识别商品信息，消除了传统手动计算的环节，大大提高了收银速度。",
      "RFID技术可以自动识别商品信息，消除了人为计算的误差。",
      "自动完成收银和结账等操作，降低了店员的工作量，减少了人力成本。",
      "自动记录商品信息和交易信息，减少盗窃和误操作的风险，提高安全性。",
      "自动记录销售数据，实时汇总和分析营业额数据，为经营决策提供参考。",
      "自动记录客户购买信息，便于分析客户信息，为营销提供更多可能性。",
    ],
    video: "cash.mp4",
  },
];
import plansTitle from "@/views/plans/components/Title.vue";

export default {
  data() {
    return {};
  },
  components: {
    plansTitle,
  },
  created() {
    this.videoShowData = videoShowData.find(
      (item) => item.name == this.$route.query.name
    );
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  img {
    width: 100%;
  }
  .videoShow {
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 0.5rem;
  }
  .background {
    background-color: #f7f8fa;
  }
  .content {
    display: flex;
    width: 13.2rem;
    margin: 0 auto;
    background: linear-gradient(180deg, #f8f9fb 0%, #ffffff 100%);
    box-shadow: 0px 4px 11px 3px rgba(109, 154, 148, 0.22);
    padding: 0.5rem;
    box-sizing: border-box;
    @if ($searchWidth<1100) {
      width: 100%;
      flex-direction: column;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
  .left {
    flex: 1;
    .subTitle {
      font-size: 24px;
      font-weight: normal;
      color: #333333;
      line-height: 46px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    }
    .meritWarp {
      .meritItem {
        display: flex;
        margin-top: 0.2rem;
        margin-bottom: 15px;
        img {
          position: relative;
          top: 4px;
          width: 18px;
          height: 18px;
        }
        .merits {
          margin-left: 4px;
          font-size: 16px;
          font-weight: normal;
          color: #1d403b;
          line-height: 25px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        }
      }
    }
  }
  .right {
    margin-left: 0.5rem;
    width: 6.4rem;
    @if ($searchWidth<1100) {
      margin: 0;
      width: 100%;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
