<template>
  <!--  衣服盘点&智能货架的使用场景组件-->
  <div
    class="scene"
    v-if="sceneDate"
    :class="{ isWhite: sceneDate.background }"
  >
    <plansTitle :title="sceneDate.title"></plansTitle>
    <div class="sceneWarp wow fadeInUp">
      <div
        class="sceneItem"
        v-for="(item, index) in sceneDate.child"
        :key="index"
      >
        <div class="left">
          <img :src="require('@/assets/plans/sence/' + item.img)" />
        </div>
        <div class="right">
          <div class="rightTop">
            <img :src="require('@/assets/plans/sence/' + item.icon)" />
            <div class="rightTitle">
              {{ item.name }}
            </div>
          </div>
          <div class="rightMes">
            {{ item.mes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import plansTitle from "@/views/plans/components/Title.vue";

const sceneDate = [
  {
    name: "衣服盘点",
    title: "RFID服装盘点的场景",
    background: "white",
    child: [
      {
        name: "零售店铺",
        icon: "icon1.png",
        img: "1.png",
        mes: "商家可以在衣服标签中嵌入RFID 芯片，用RFID读写器扫描这些标 签来实现库存盘点，以便快速准 确地了解库存情况，避免因为库 存短缺或过剩而造成损失。",
      },
      {
        name: "物流仓库",
        icon: "icon2.png",
        img: "2.png",
        mes: "使用RFID技术可实现物流仓库对 物流箱或货架的实时盘点，从而 提高物流仓库运作效率和准确性， 降低库存管理成本。",
      },
    ],
  },
  {
    name: "贴标管理",
    title: "RFID贴标管理的场景",
    child: [
      {
        name: "标签复合",
        icon: "icon3.png",
        img: "10.png",
        mes: "RFID标签可以对物品进行快速的追踪和管理，标签复合需要在RFID芯片和天线上覆盖封装材料，以防止损坏和污染。",
      },
      {
        name: "标签抽检",
        icon: "icon3.png",
        img: "5.png",
        mes: "对部分标签进行人为筛查而控制标签质量，利于保证标签的读取率和使用效果，提高工作效率。",
      },
    ],
  },
  {
    name: "智能货架",
    title: "RFID智能货架应用场景",
    child: [
      {
        name: "家乐福",
        icon: "icon1.png",
        img: "3.png",
        mes: "这家零售企业通过采用RFID智能 货架，实现了商品包装自动化， 加快了售货员的销售效率。",
      },
      {
        name: "全棉时代",
        icon: "icon1.png",
        img: "4.png",
        mes: "这个品牌在新零售门店尝试使用 RFID智能货架，帮助提高商品的 商品管理效率。",
      },
    ],
  },
  {
    name: "智能试衣间",
    title: "RFID试衣间场景",
    background: "white",
    child: [
      {
        name: "智能试衣",
        icon: "icon1.png",
        img: "6.png",
        mes: "顾客在试衣间内可以使用屏幕上 的设备扫描衣物的RFID吊牌，屏 幕上即可显示衣物的详细信息， 帮助顾客快速选购。",
      },
      {
        name: "数据采集",
        icon: "icon2.png",
        img: "7.png",
        mes: "通过RFID技术可以采集到顾客购 买偏好、试衣率等，有利于进行 库存管理和制定营销策略。",
      },
    ],
  },
  {
    name: "防盗防损",
    title: "RFID防盗防损场景",
    background: "white",
    child: [
      {
        name: "防盗门",
        icon: "icon1.png",
        img: "8.png",
        mes: "在商品上附加RFID标签，如果买家试衣时未能归还商品，则在离开商店时会被检测到并触发警报。",
      },
      {
        name: "质量追踪",
        icon: "icon7.png",
        img: "9.png",
        mes: "在鞋服上标记RFID标签可追踪其历史，如果消费者反映衣物存在质量问题，鞋服店就可以通过系统来溯源退货。",
      },
    ],
  },
];
export default {
  created() {
    this.sceneDate = sceneDate.find(
      (item) => item.name == this.$route.query.name
    );
  },
  components: {
    plansTitle,
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .scene {
    width: 100%;
    padding: 0 0 1rem;
    background-image: url("~@/assets/plans/sence/bg.png");
    @if ($searchWidth<1100) {
      padding: 0 10px 1.5rem;
      box-sizing: border-box;
    }
  }
  .isWhite {
    background-image: none;
    background: #ffffff;
  }
  .sceneWarp {
    display: flex;
    justify-content: center;
    @if ($searchWidth<1100) {
      flex-direction: column;
    }
  }
  .sceneItem {
    margin-right: 0.3rem;
    border-radius: 5px;
    width: 610px;
    background: linear-gradient(180deg, #f8f9fb 0%, #ffffff 100%);
    box-shadow: 0px 4px 11px 3px rgba(109, 154, 148, 0.22);
    display: flex;
    @if ($searchWidth<1100) {
      width: 100%;
      margin-bottom: 20px;
      flex-direction: column;
    }
    .left {
      @if ($searchWidth<1100) {
        width: 100%;
        img {
          width: 100%;
        }
      } @else {
        width: 3rem;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
    }
    .right {
      flex: 1;
      padding: 0.26rem 0.3rem 0 0.3rem;
      @if ($searchWidth<1100) {
        padding: 10px 10px 0;
      }
      .rightTop {
        display: flex;
        align-items: center;
        img {
          width: 32px;
        }
      }
      .rightTitle {
        font-size: 0.2rem;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 26px;
        letter-spacing: 1px;
        margin-left: 5px;
        @if ($searchWidth<1100) {
          font-size: 1rem;
        }
      }
      .rightMes {
        margin-top: 7px;
        font-size: 16px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #7a7a7a;
        line-height: 25px;
        letter-spacing: 1px;
        @if ($searchWidth<1100) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
