<template>
  <!--  方案价值组件-->
  <div
    class="plansValue"
    v-if="valueDate"
    :class="{ plansValueGoods: valueDate.background }"
  >
    <plansTitle :title="valueDate.title"></plansTitle>
    <div class="content wow fadeInUp">
      <div
        class="contentWarp"
        :class="'contentWarp' + index"
        v-for="(childList, index) in valueDate.child"
        :key="index"
      >
        <div
          class="valueItem"
          v-for="(item, indexs) in childList"
          :key="indexs"
        >
          <div class="arrows" v-if="item.arrow">
            <img :src="require('@/assets/plans/values/' + item.arrow)" />
          </div>
          <div class="itemWarp" v-else>
            <div class="tops">
              <img :src="require('@/assets/plans/values/' + item.icon)" />
              <div class="names">
                {{ item.name }}
              </div>
            </div>
            <div class="mes">
              {{ item.mes }}
            </div>
          </div>
        </div>
        <img
          class="curveds"
          :src="require('@/assets/plans/values/curved.png')"
          v-if="index == 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import plansTitle from "@/views/plans/components/Title.vue";

const plansValueData = [
  {
    name: "贴标管理",
    title: "RFID贴标管理的价值",
    child: [
      [
        {
          name: "提高物流管理效率",
          icon: "1-1.png",
          mes: "RFID贴标管理可以实现对物流过程中的物品进行自动化识别和跟踪，避免了传统物流管理中需要人工操作的缺点，大大提高了物流管理的效率。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "降低人工成本",
          icon: "1-2.png",
          mes: "RFID贴标管理可以实现对物品的自动化管理，避免了人工操作和管理的繁琐和耗时，降低了人工成本和管理成本。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "提高库存管理精度",
          icon: "1-3.png",
          mes: "RFID贴标管理可以实现对库存物品的自动化识别和跟踪，实时掌握物品的数量和位置，提高了库存管理的精度和实时性。",
        },
      ],
      [
        {
          name: "提高生产制造效率",
          icon: "1-4.png",
          mes: "RFID贴标管理可以实现对生产制造过程中的物品进行自动化跟踪和管理，提高了生产制造的效率和精度，降低了产品制造的成本和质量问题。",
        },
        {
          arrow: "left.png",
        },
        {
          name: "加强对物品的追溯和溯源",
          icon: "1-5.png",
          mes: "RFID贴标管理可以对物品进行全生命周期的管理，记录了物品的进出库、运输、存储等 信息，可以实现对物品的追溯和溯源，避免 了商品流通过程中的不良行为，保障了消费者权益。",
        },
      ],
    ],
  },
  {
    name: "衣服盘点",
    title: "RFID服装盘点的价值",
    background: true,
    child: [
      [
        {
          name: "提高盘点效率",
          icon: "2-1.png",
          mes: "RFID标签可以在没有物理接触的情况下进行读取，这意味着工作人员可以更快速地扫描和识别库存中的产品，减少盘点过程中的时间和劳动力成本。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "减少盘点误差",
          icon: "2-2.png",
          mes: "RFID标签可以自动读取，减少了手动数据输入的风险，减少了人为错误的机会，提高了库存数据的准确性。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "提高库存管理效率",
          icon: "2-3.png",
          mes: "RFID标签可以跟踪和管理库存，提高了库存的可视性和可控性，有助于减少废料和库存浪费。",
        },
      ],
      [
        {
          name: "增强安全性",
          icon: "2-4.png",
          mes: "RFID标签可以增强商品的安全性，减少损失和盗窃风险。在实践中，RFID技术被广泛应用于防盗标签和防伪标签等方面，可以使零售商更好地保护其商品和客户。",
        },
        {
          arrow: "left.png",
        },
        {
          name: "提高客户满意度",
          icon: "2-5.png",
          mes: "RFID技术可以使库存数据更加准确，从而减少缺货和超售的情况，为客户提供更好的服务和购物体验。",
        },
      ],
    ],
  },
  {
    name: "智能货架",
    title: "RFID货架的价值",
    child: [
      [
        {
          name: "提高库存可见性",
          icon: "3-1.png",
          mes: "通过在每个衣架或衣服上附加RFID标签，零售商可以实时跟踪其库存水平，了解每个产品的位置、数量和销售情况。这样可以更准确地了解哪些商品卖得最好，哪些商品需要再进货，从而更好地满足消费者的需求。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "提高效率",
          icon: "3-2.png",
          mes: "RFID技术可以自动读取标签信息，从而节省了时间和劳动力。传统上，零售商需要手动扫描每个标签来更新库存信息，这往往需要花费很多时间和精力。RFID技术可以大大缩短更新库存信息的时间，从而提高工作效率。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "减少库存损失",
          icon: "3-3.png",
          mes: "RFID技术可以帮助零售商更好地控制库存，减少库存损失。如果一个产品被窃取或错误地放置在错误的位置，RFID系统可以立即检测到这个问题，并让员工立即采取行动。",
        },
      ],
      [
        {
          name: "提高销售",
          icon: "3-4.png",
          mes: "通过更好地了解库存情况和客户行为，RFID技术可以帮助零售商优化产品选择和陈列方式，从而提高销售额。",
        },
        {
          arrow: "left.png",
        },
        {
          name: "提高客户服务",
          icon: "3-5.png",
          mes: "RFID技术可以帮助零售商更好地了解客户购物行为和喜好。例如，如果RFID系统发现某个产品经常被客户查看或试穿，零售商可以根据这些信息调整其库存策略，提供更满足客户需求的产品选择。",
        },
      ],
    ],
  },
  {
    name: "防盗防损",
    title: "RFID防盗防损的价值",
    background: true,
    child: [
      [
        {
          name: "减少损失和盗窃",
          icon: "4-1.png",
          mes: "RFID标签可以有效地跟踪商品的位置和运动。通过在进出门口或其他区域安装RFID读取器，商家可以及时地检测到未付款商品的出库，减少盗窃和损失。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "提高库存管理效率",
          icon: "4-2.png",
          mes: "RFID技术可以帮助商家快速、准确地记录库存。商家可以使用RFID读取器来实时监测库存水平，确保每个物品都被跟踪和记录。这有助于降低库存成本和滞留库存的风险。",
        },
        {
          arrow: "right.png",
        },
        {
          name: "提高客户满意度",
          icon: "4-3.png",
          mes: "RFID技术可以帮助商家更好地满足客户需求。例如，商家可以使用RFID标签为客户提供个性化的建议和产品推荐。这有助于增加客户忠诚度和满意度，提高客户体验。",
        },
      ],
      [
        {
          name: "可追溯性",
          icon: "4-4.png",
          mes: "RFID标签可以帮助商家跟踪商品的生命周期。商家可以使用RFID标签记录商品的生产、运输、存储和销售情况，从而提高可追溯性和透明度，为消费者提供更好的服务和支持。",
        },
        {
          arrow: "left.png",
        },
        {
          name: "降低劳动力成本",
          icon: "4-5.png",
          mes: "RFID技术可以帮助商家自动化一些库存和销售流程，从而减少人工干预。这有助于降低人力成本，提高工作效率和准确性。",
        },
      ],
    ],
  },
];
export default {
  created() {
    this.valueDate = plansValueData.find(
      (item) => item.name == this.$route.query.name
    );
  },
  components: {
    plansTitle,
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .plansValue {
    width: 100%;
    background: #ffffff;
    margin-bottom: 0.2rem;
  }
  .plansValueGoods {
    background-image: url("~@/assets/plans/sence/bg.png");
    margin-bottom: 0;
    padding-bottom: 20px;
  }
  .content {
    width: 100%;
    margin: 0.2rem auto 0;
    @if ($searchWidth<1100) {
      flex-direction: column;
      margin-top: 30px;
    }
  }
  .contentWarp {
    width: max-content;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    @if ($searchWidth<1100) {
      flex-direction: column;
      width: 100%;
    }
  }
  .contentWarp1 {
    @if ($searchWidth<1100) {
      flex-direction: column-reverse;
    }
  }
  .curveds {
    position: absolute;
    right: -176px;
    bottom: 140px;
    width: 90px;
    img {
      width: 100%;
    }
    @if ($searchWidth<1100) {
      display: none;
    }
  }
  .valueItem {
    .itemWarp {
      width: 380px;
      height: 172px;
      box-sizing: border-box;
      background: linear-gradient(
        358deg,
        rgba(251, 255, 254, 0.11) 0%,
        #edf8f6 100%
      );
      border: 1px dashed #cadedb;
      padding: 36px 5px 36px 22px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      position: relative;
      margin: 0 16px 60px;
      @if ($searchWidth<1100) {
        width: calc(100% - 40px);
        box-sizing: border-box;
        margin: 0 20px 60px;
      }
    }
    .tops {
      position: absolute;
      top: -21px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 261px;
      height: 42px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 21px;
      border: 1px solid #5ec7b9;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      img {
        width: 32px;
        height: 32px;
      }
      .names {
        margin-left: 6px;
        font-size: 20px;
        font-weight: normal;
        color: #333333;
        line-height: 26px;
        letter-spacing: 1px;
        white-space: nowrap;
      }
    }
    .mes {
      font-size: 16px;
      font-weight: normal;
      color: #7a7a7a;
      line-height: 25px;
      letter-spacing: 1px;
    }
  }
  .arrows {
    width: 30px;
    height: 25px;
    img {
      width: 100%;
    }
    @if ($searchWidth<1100) {
      display: none;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
