<template>
  <top :title="'解决方案'"></top>
  <introduce></introduce>
  <step></step>
  <fittingApply></fittingApply>
  <flowPath></flowPath>
  <mobileCheck></mobileCheck>
  <CashierPain></CashierPain>
  <fittingEqument></fittingEqument>
  <stealTechnology></stealTechnology>
  <voluntarilyCash></voluntarilyCash>
  <pcInterface></pcInterface>
  <fittingValue></fittingValue>
  <plansValue></plansValue>
  <quickFind></quickFind>
  <videoShow></videoShow>
  <scene></scene>
  <div
    v-if="bottomLine"
    :style="{
      margin: '0 0 .4rem',
      height: '.01rem',
      background: '#E5E6EB',
    }"
  ></div>
  <common-footer />
</template>
<script>
import top from "@components/top.vue";
import introduce from "@/views/plans/components/introduce.vue";
import step from "@/views/plans/components/step.vue";
import scene from "@/views/plans/components/scene.vue";
import flowPath from "@/views/plans/components/flowPath.vue";
import videoShow from "@/views/plans/components/videoShow.vue";
import fittingEqument from "@/views/plans/components/fittingEqument.vue";
import stealTechnology from "@/views/plans/components/stealTechnology.vue";
import CashierPain from "@/views/plans/components/CashierPain.vue";
import fittingApply from "@/views/plans/components/FittingApply.vue";
import mobileCheck from "@/views/plans/components/mobileCheck.vue";
import plansValue from "@/views/plans/components/plansValue.vue";
import quickFind from "@/views/plans/components/quickFind.vue";
import fittingValue from "@/views/plans/components/fittingValue.vue";
import voluntarilyCash from "@/views/plans/components/voluntarilyCash.vue";
import pcInterface from "@/views/plans/components/pcInterface.vue";
// import axios from "axios";
import CommonFooter from "@components/footer.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    CommonFooter,
    introduce,
    fittingApply,
    scene,
    flowPath,
    videoShow,
    fittingEqument,
    pcInterface,
    stealTechnology,
    CashierPain,
    mobileCheck,
    step,
    plansValue,
    fittingValue,
    voluntarilyCash,
    quickFind,
    top,
  },
  data() {
    return {
      bottomLine: false,
    };
  },
  created() {
    this.bottomLine = [
      "衣服盘点",
      "智能试衣间",
      "快速查找",
      "防盗防损",
    ].includes(this.$route.query.name);
  },
  computed: {},
  methods: {
    ...mapMutations([
      "setHeaderColorBlack",
      "setHeaderColorWhite",
      "setheaderTopBtnWhite",
    ]),
  },

  mounted() {
    this.setheaderTopBtnWhite(false);
  },
  unmounted() {
    this.setheaderTopBtnWhite(false);
  },
};
</script>
<style lang="scss" scoped></style>
