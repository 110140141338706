<template>
  <!--  快速收银页自动收银组件-->
  <div class="voluntarilyCash" v-if="voluntarilyCash">
    <plansTitle :title="'自助收银操作界面'"></plansTitle>
    <div class="content">
      <div class="left wow fadeInLeftBig">
        <img class="cashs" :src="require('@/assets/plans/fastCash/cash.png')" />
        <img
          class="arrows"
          :src="require('@/assets/plans/fastCash/rightArrow.png')"
        />
      </div>
      <div class="right wow fadeInRightBig">
        <div
          class="ItemWarp"
          v-for="(item, index) in voluntarilyCashData"
          :key="index"
        >
          <img :src="require('@/assets/plans/fastCash/' + item.img)" />
          <div class="names">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const voluntarilyCashData = [
  {
    name: "开始",
    img: "1.png",
  },
  {
    name: "扫描商品",
    img: "2.png",
  },
  {
    name: "结算付款",
    img: "3.png",
  },
  {
    name: "结束",
    img: "4.png",
  },
];
</script>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  created() {
    this.voluntarilyCash = "快速收银" == this.$route.query.name;
  },
  components: {
    plansTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .voluntarilyCash {
    width: 100%;
    background-image: url("~@/assets/plans/sence/bg.png");
    padding-bottom: 0.8rem;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    @if ($searchWidth<1100) {
      flex-direction: column;
    }
  }
  .left,
  .right {
    display: flex;
    align-items: center;
  }
  .cashs {
    @if ($searchWidth<1100) {
      width: 90%;
      margin: 0 auto;
    } @else {
      width: 515px;
      height: 416px;
      margin-right: 36px;
    }
  }
  .arrows {
    width: 85px;
    height: 119px;
    @if ($searchWidth<1100) {
      display: none;
    }
  }
  .right {
    @if ($searchWidth<1100) {
      flex-wrap: wrap;
    }
  }
  .ItemWarp {
    display: flex;
    flex-direction: column;
    align-items: center;
    @if ($searchWidth<1100) {
      width: 50%;
    }
    img {
      @if ($searchWidth<1100) {
        width: 100%;
      } @else {
        width: 179px;
        height: 320px;
      }
    }
    .names {
      background: #ffffff;
      box-shadow: 0px 2px 10px 9px #f0f5f4;
      width: 141px;
      height: 22px;
      padding: 5px 0;
      font-size: 16px;
      text-align: center;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 22px;
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
