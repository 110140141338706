<template>
  <div
    class="pcInterface"
    v-if="valueDate"
    :class="{ background: valueDate.background }"
  >
    <Title :title="valueDate.topTitle"></Title>
    <div class="contents">
      <div class="left wow fadeInLeftBig">
        <img :src="require('@/assets/plans/pcInterface/' + valueDate.img)" />
      </div>
      <div class="right wow fadeInRightBig">
        <div class="titles">
          {{ valueDate.title }}
        </div>
        <div class="sub-titles">
          {{ valueDate.subTitle }}
        </div>
        <div class="merits">
          <div
            class="merit-item"
            v-for="(item, index) in valueDate.merits"
            :key="index"
          >
            <img :src="require('@/assets/gou.png')" />
            <div>
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const pcInterfaceData = [
  {
    name: "衣服盘点",
    topTitle: "RFID服装盘点",
    title: "RFID服装盘点的优势：",
    subTitle: "利用RFID技术对服装库存数量和状态进行快速高效的自动化盘点。",
    img: "1.png",
    merits: [
      "提高盘点效率和准确率；",
      "实时更新库存信息，改进库存管理； ",
      "改善顾客体验，并提高转化率；",
      "消除人为错误，降低操作成本。",
    ],
  },
  {
    name: "智能货架",
    topTitle: "RFID智能货架",
    title: "RFID智能货架的优势：",
    subTitle:
      "运用RFID技术实现对货架进行实时检测、监控和自动化调节，提高了服装库存管理的智能化。",
    background: true,
    img: "2.png",
    merits: [
      "实现智能化、自动化的货架管理，高效节省人力，降低运营成本；",
      "提升顾客购物体验和营销效果 ；",
      "避免信息丢失，方便库存和处置管理；",
      "数据分析和优化，提高销售额和营销效果。",
    ],
  },
  {
    name: "智能试衣间",
    topTitle: "RFID智能试衣间",
    title: "RFID智能试衣间的优势：",
    subTitle:
      "通过RFID技术实现对试衣间内试穿的服装的自动辨别、记录等功能，提高了客户体验和销售效率。",
    img: "3.png",
    merits: [
      "提升购物体验和顾客满意度；",
      "实现精准商品记录和库存管理； ",
      "缩短试衣时间，提高试衣率和销售转化率；",
      "实现移动支付和O2O服务，提供更为便捷的购物服务。",
    ],
  },
  {
    name: "快速收银",
    topTitle: "RFID快速收银",
    title: "RFID快速收银核心亮点：",
    subTitle:
      "利用RFID技术实现快速读取商品信息、自动计算价格、自动核对库存和收银等操作。",
    img: "4.png",
    merits: [
      "提高收银效率，降低运营成本；",
      "缩短顾客等待时间，提高顾客满意度和体验； ",
      "实现精细化管理和决策；",
      "实现多渠道支付和O2O营销的无缝连接。",
    ],
  },
  {
    name: "防盗防损",
    topTitle: "RFID防盗防损",
    title: "RFID防盗防损的优势：",
    subTitle:
      "利用RFID技术实现对商品实时监测和追踪，并自动发出警报和防损措施，保护商户的财产安全。",
    img: "5.png",
    merits: [
      "有效减少盗窃和错误配送，降低安全风险；",
      "提高商品美观度，易于隐藏； ",
      "提高售货员工作效率，消除误报和漏报；",
      "实现精细化管理和决策。",
    ],
  },
];
import Title from "@/views/plans/components/Title.vue";
export default {
  components: {
    Title,
  },
  created() {
    this.valueDate = pcInterfaceData.find(
      (item) => item.name == this.$route.query.name
    );
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .pcInterface {
    width: 100%;
    background: #ffffff;
    padding-bottom: 0.8rem;
  }
  .background {
    background-image: url("~@/assets/plans/sence/bg.png");
  }
  .contents {
    display: flex;
    justify-content: center;
    align-items: center;
    @if ($searchWidth<1100) {
      flex-direction: column;
    }
  }
  .left {
    max-width: 664px;
    @if ($searchWidth<1100) {
      max-width: 95%;
    }
    img {
      width: 100%;
    }
  }
  .right {
    max-width: 3.76rem;
    margin-left: 1.27rem;
    padding-right: 1.53rem;
    @if ($searchWidth<1100) {
      max-width: 100%;
    }
    .titles {
      font-size: 0.24rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 24px;
      @if ($searchWidth<1100) {
        font-size: 1.2rem;
        margin-top: 10px;
      }
    }
    .sub-titles {
      font-size: 0.18rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1d403b;
      line-height: 24px;
      margin: 0.15rem 0 0.2rem;
      @if ($searchWidth<1100) {
        font-size: 1.1rem;
        margin: 10px 0 15px;
      }
    }
    .merits {
      .merit-item {
        display: flex;
        align-items: baseline;
        img {
          width: 16px;
          height: 16px;
          @if ($searchWidth>1100) {
            transform: translateY(3px);
          }
        }
        div {
          font-size: 0.16rem;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #7a7a7a;
          line-height: 46px;
          margin-left: 0.12rem;
          white-space: nowrap;
          @if ($searchWidth<1100) {
            font-size: 1rem;
            line-height: 1.8rem;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
