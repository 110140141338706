<template>
  <!--  衣服盘点页手持机组件-->
  <div class="mobileCheck" v-if="mobileCheck">
    <plansTitle :title="'登录手持机APP，打开门店盘点页面'"></plansTitle>
    <div class="content">
      <div class="left wow fadeInLeftBig">
        <div class="titles">{{ "门店盘点系统" }}</div>
        <div
          class="merits"
          v-for="(item, index) in mobileCheckData"
          :key="index"
        >
          <img :src="require('@/assets/gou.png')" />
          <div class="meritItem">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="right">
        <div
          class="imgWarp wow fadeInRightBig"
          v-for="(item, index) in mobileCheckImgs"
          :key="index"
        >
          <img :src="require('@/assets/plans/clothsCheck/' + item)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const mobileCheckData = [
  "支持条码+RFID唯一码共建盘点单，上线初期即可实现快速、准确盘点；",
  "可以设置定期盘点功能(日盘，周盘，月盘等)；",
  "支持无网或弱网环境下都能对RFID产品的感应采集；",
  "支持数据本地缓存，有网环境数据提交。",
];
const mobileCheckImgs = ["1.png", "2.png", "3.png", "4.png"];
</script>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  created() {
    this.mobileCheck = "衣服盘点" == this.$route.query.name;
  },
  components: {
    plansTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .mobileCheck {
    width: 100%;
    background: #f7f8fa;
    padding-bottom: 0.8rem;
    @if ($searchWidth<1100) {
      padding: 10px 10px;
      box-sizing: border-box;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    @if ($searchWidth<1100) {
      flex-direction: column;
    }
  }
  .left {
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    margin-right: 88px;
    margin-top: 15px;
    @if ($searchWidth<1100) {
      margin: 0;
      margin-bottom: 10px;
    }
    .titles {
      font-size: 24px;
      font-weight: normal;
      color: #333333;
      line-height: 46px;
      margin-bottom: 12px;
      @if ($searchWidth<1100) {
        text-align: center;
      }
    }
    .merits {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        align-self: flex-start;
        transform: translateY(15px);
      }
      .meritItem {
        margin-left: 12px;
        font-size: 16px;
        font-weight: normal;
        color: #1d403b;
        line-height: 46px;
      }
    }
  }
  .right {
    display: flex;
    @if ($searchWidth<1100) {
      flex-wrap: wrap;
    }
    .imgWarp {
      @if ($searchWidth<1100) {
        flex-direction: column;
        width: 50%;
        text-align: center;
        img {
          width: 100%;
        }
      } @else {
        width: 160px;
        margin-right: 1px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
