<template>
  <!--  试衣间应用组件-->
  <div class="FittingApply" v-if="FittingApply">
    <plansTitle :title="'RFID智能试衣间的应用'"></plansTitle>
    <div class="content wow fadeInUp">
      <div class="applyItem" v-for="(item, index) in ApplyData" :key="index">
        <img :src="require('@/assets/plans/fittingRoom/' + item.icon)" />
        <div class="titles">{{ item.title }}</div>
        <div class="mes">{{ item.mes }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
const ApplyData = [
  {
    title: "自动识别尺码和颜色",
    icon: "icon1.png",
    mes: "智能试衣间内的RFID读写器可以识别商品上的RFID标签，自动显示相应的尺码和颜色，消除了顾客在试衣间内查找尺码和颜色的烦恼。",
  },
  {
    title: "推荐商品",
    icon: "icon2.png",
    mes: "RFID智能试衣间还可以通过读取RFID标签，了解顾客试衣间内所携带的商品信息，推荐相关搭配商品，为顾客提供更为个性化的购物体验。",
  },
  {
    title: "在线支付",
    icon: "icon3.png",
    mes: "RFID智能试衣间可以通过连接支付系统，为顾客提供在线支付服务，方便快捷。",
  },
  {
    title: "试衣记录",
    icon: "icon4.png",
    mes: "RFID智能试衣间可以记录顾客试穿的商品信息，方便顾客随时查看，避免遗忘或重复购买。",
  },
  {
    title: "数据分析",
    icon: "icon5.png",
    mes: "RFID智能试衣间可以收集顾客的试穿数据，并通过数据分析和挖掘，了解顾客的试衣偏好和购物习惯，为商家提供更为准确的市场营销策略。",
  },
];
</script>
<script>
import plansTitle from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {};
  },
  created() {
    this.FittingApply = "智能试衣间" == this.$route.query.name;
  },
  components: {
    plansTitle,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .FittingApply {
    width: 100%;
    background: #ffffff;
    padding-bottom: 0.8rem;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    @if ($searchWidth<1100) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  .applyItem {
    width: 198px;
    height: 257px;
    padding: 30px 17px;
    background: linear-gradient(180deg, #f8f9fb 0%, #ffffff 100%);
    box-shadow: 0px 4px 11px 3px rgba(109, 154, 148, 0.22);
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
    @if ($searchWidth<1100) {
      margin: 10px;
      width: calc(50% - 20px);
      box-sizing: border-box;
      min-height: 380px;
    }
    img {
      width: 48px;
      height: 48px;
    }
    .titles {
      margin: 15px 0;
      font-size: 20px;
      font-weight: normal;
      color: #333333;
      line-height: 26px;
      letter-spacing: 1px;
    }
    .mes {
      font-size: 16px;
      font-weight: normal;
      color: #7a7a7a;
      line-height: 25px;
      letter-spacing: 1px;
    }
  }
  :last-child {
    @if ($searchWidth>1100) {
      margin-right: 0;
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
